import { useEffect } from 'react'

// solution that detects onClick events outside the component => https://reactjs.org/docs/accessibility.html
export default function useOnClickOutside (elementRef, toggleElement, handler) {
  useEffect(() => {
    function onClickOutsideHandler (evt) {
      // if the element clicked is the component itself, nothing happens
      if (!elementRef.current || elementRef.current.contains(evt.target)) return
      // if the click was outside the component, then it should be closed
      handler && handler(false)
    }

    document.addEventListener('mousedown', onClickOutsideHandler)
    document.addEventListener('touchstart', onClickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler)
      document.removeEventListener('touchstart', onClickOutsideHandler)
    }
  }, [])
}
