import React, { useState, useRef, useEffect } from 'react'
import MarkdownIcon from '../UI/MarkdownIcon'

function HelpMeBoxContent ({ content, pathname, slugAllAnswers }) {
  const inputRef = useRef(null)
  const [ inputValue, setInputValue ] = useState('')
  const [ contentIndex, setContentIndex ] = useState('conta-digital')
  const [ buttonSlug, setButtonSlug ] = useState('')

  useEffect(() => {
    let verify = false
    Object.keys(content).map((item) => {
      if (pathname.includes(item)) {
        setContentIndex(item)
        setButtonSlug(slugAllAnswers[item])
        verify = true
      }
    })

    if (!verify) {
      setContentIndex('conta-digital')
      setButtonSlug('')
    }
  }, [ pathname ])

  function handleChange (evt) {
    setInputValue(evt.target.value)
  }

  function handleSubmit (evt) {
    inputRef.current.value.length === 0 && evt.preventDefault()
  }

  return (
    <>
      <form
        name='babi-search-form' action='https://ajuda.bancointer.com.br/pt-BR/' method='get'
        className='box-babi-content__form mb-2' onSubmit={(evt) => handleSubmit(evt)} target='_blank'
      >
        <input
          id='search'
          name='q'
          placeholder='Busque respostas...'
          aria-label='Busque respostas'
          type='text'
          value={inputValue}
          onChange={(evt) => handleChange(evt)}
          ref={inputRef}
        />
        <button className='submit' type='submit' aria-label='busque respostas'>
          <MarkdownIcon width='20' height='20' icon='navigation/search' directory='v2' />
        </button>
      </form>
      <ul>
        {
          content[contentIndex].map((item) => {
            return (
              <li key={item.title} className='box-babi-content__item mb-2'>
                <a
                  className='d-block p-2' href={`https://ajuda.bancointer.com.br/pt-BR/${item.slug}`}
                  target='_blank' rel='noreferrer'
                >
                  <p className='title'>{item.title}</p>
                  <div className='d-flex align-items-center'>
                    <img className='profile-pic mr-1' src={item.img} alt='Img de perfil' />
                    <p className='author'>Escrito por <span>{item.author}</span></p>
                  </div>
                </a>
              </li>
            )
          })
        }
      </ul>
      <a
        className='btn btn--lg btn--orange mt-2 mb-3'
        href={`https://ajuda.bancointer.com.br/pt-BR/${buttonSlug}`}
        target='_blank' rel='noreferrer'
      >Ver todas as respostas
      </a>
    </>
  )
}

export default HelpMeBoxContent
