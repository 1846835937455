import React from 'react'
import { useLocation } from '@reach/router'

import HelpMeBox from 'src/components/HelpMeBox'

const Babi = () => {
  const location = useLocation()

  return <HelpMeBox pathname={location.pathname} theme={{ name: 'default' }} scrolled={false} />
}

export default Babi
