import React, { useLayoutEffect, useState, useRef } from 'react'
import { LoadingIndicator } from 'inter-site-components'
import Chevron from 'inter-frontend-svgs/lib/layout/chevron'
import HelpMeBoxContent from './HelpMeBoxContent'
import useOnClickOutside from 'src/hooks/window/useOnClickOutside'
import data from '../../../static/Home/pt/BoxBabi.json'

import './helpMeBox.scss'

function HelpMeBox ({ pathname, theme, scrolled }) {
  const contentRef = useRef(null)
  const containerRef = useRef(null)

  const [ boxOpen, setBoxOpen ] = useState(false)
  const [ collapsibleHeight, setCollapsibleHeight ] = useState(0)
  const [ isActive, setIsActive ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)

  const defaultTheme = theme.name === 'default'

  useOnClickOutside(containerRef, boxOpen, setBoxOpen)

  useLayoutEffect(() => {
    setCollapsibleHeight(contentRef.current.scrollHeight)
  }, [ pathname, collapsibleHeight ])

  function collapseContent (evt) {
    evt && evt.preventDefault()
    !boxOpen && setIsActive(true)
    setBoxOpen(!boxOpen)
  }

  return (
    <div className='position-relative' ref={containerRef}>
      <button
        data-menu='Central de Ajuda'
        className={`box-babi ${isActive ? 'box-babi--active' : ''} ${
          scrolled ? 'scrolled' : ''
        } ${defaultTheme ? 'default' : ''}
        `}
        onClick={(evt) => collapseContent(evt)}
      >
        <div className='d-flex justify-content-start align-items-center'>
          {isLoading ? <LoadingIndicator size='medium' /> : ''}
          <img
            src={data.profilePic}
            width='34'
            height='34'
            alt='Babi Profile'
            onLoad={() => setIsLoading(false)}
          />
          <p className='mx-3'>Central de Ajuda</p>
          <div
            className={`box-chevron position-relative mt-1 ${
              boxOpen ? 'box-chevron--active' : ''
            }`}
          >
            <Chevron width='16' height='8' rotate='0' />
          </div>
        </div>
      </button>
      <div
        ref={contentRef}
        onTransitionEnd={() => !boxOpen && setIsActive(false)}
        style={{ height: boxOpen ? collapsibleHeight : 0 }}
        className='box-babi-content'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-2'>
              <HelpMeBoxContent
                content={data.content}
                pathname={pathname}
                slugAllAnswers={data.slugAllAnswers}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpMeBox
